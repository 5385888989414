import React, { useState, useEffect }  from 'react';
import {setGroup, getAssessments, deleteAssessment} from '../reducers/user';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, Navigate, Link } from "react-router-dom";
import { Button } from '../components/Button';
import { Header } from '../components/Header'

export function Home(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    if(!user.isLoggedIn){
        return <Navigate to={'/login'} />
    }

    if(!user.company){
        return <Navigate to={'/full-profile'} />
    }

    useEffect(() => {
        dispatch(getAssessments())
    }, [dispatch])

    return (
        <>
            <Header />
            <section className={'py-20'}>
                <div className={'container mx-auto'}>
                    <h1 className={'text-3xl mb-5'}>FinOps Assessment Dashboard</h1>

                    <div className="grid mt-10 grid-cols-2 gap-9">
                        <div>
                            <div className="p-10 bg-white rounded-lg shadow-xl">
                                <h2 className={'text-2xl mb-4'}>Start A New Assessment</h2>
                                <p>Assess the FinOps capabilities of a new area of the organization. Creating a FinOps assessment gauges where they are and how far they need to go towards your organizational goals.</p>

                                <Link to={'/assessment'} className={'inline-flex justify-center py-2 px-2 sm:px-4 text-sm leading-relaxed font-medium rounded-sm text-white border border-green-500 font-normal bg-green-500 hover:bg-green-600 hover:border-green-600 transition-colors duration-200 mr-2'}>Get Started</Link>
                            </div>
                        </div>

                        <div>
                            <div className="p-10 bg-white rounded-lg shadow-xl">
                                <h2 className={'text-2xl'}>Previous Assessments</h2>

                                {!!user.assessments &&
                                    <table className={'mt-4 table-fixed w-full'}>
                                        <thead>
                                            <tr>
                                                <th className={'py-4 w-7/12 text-start border-b'}>Group Assessed</th>
                                                <th className={'py-4 text-start border-b'}>Created</th>
                                                <th className={'py-4 w-1/12 border-b'} />
                                                <th className={'py-4 w-1/12 border-b'} />
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {user.assessments.map((assessment, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className={'py-4 text-start'}><strong>{assessment.group_name}</strong></td>
                                                        <td className={'py-4 text-start'}>{assessment.created_at}</td>
                                                        <td className={'py-4 text-end'}><Link to={'/summary/' + assessment.uuid} className={'text-green-600'}>View</Link></td>
                                                        <td className={'py-4 text-end'}><button onClick={ () => {
                                                            if(window.confirm('Are you sure you want to delete this assessment? This cannot be undone.')){
                                                                dispatch(deleteAssessment(assessment.uuid))
                                                            }
                                                        }} className={'text-red-600'}>Delete</button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}