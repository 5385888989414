import React, { useState, useEffect }  from 'react';
import {setGroup} from '../reducers/user';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, Navigate } from "react-router-dom";
import { Button } from '../components/Button';
import { Header } from '../components/Header'

export function Assessment(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    if(!user.isLoggedIn){
        return <Navigate to={'/login'} />
    }

    return (
        <>
            <Header />
            <section className={'py-20'}>
                <div className={'container mx-auto'}>
                    <h1 className={'text-3xl mb-5'}>Step 1: Name of the FinOps Assessment</h1>
                    <p>Identify the group being assessed. (E.g Centralized FinOps Team, Business Unit Y, etc)</p>

                    <div className="rounded-lg mt-10 w-1/2 p-10 pt-8 bg-white shadow-xl">
                        <Formik
                            initialValues={{ group: user.group }}
                            validate={values => {
                                const errors = {};
                                if (!values.group.length) {
                                    errors.group = 'Please enter a group name';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                window.scrollTo(0, 0);
                                dispatch(setGroup(values.group));
                                navigate('/capabilities');
                                setSubmitting(false);
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form className={'grid grid-cols-3 w-full gap-x-4'}>
                                    <div className="col-span-3">
                                        <label className={"mb-2 block"}><strong>Group Name:</strong></label>
                                    </div>

                                    <div className={'col-span-2'}>
                                        <Field placeholder={'Centralized FinOps Team'} type="text" className={'col-span-1 border border-gray-500'} name="group" />
                                        <ErrorMessage name="group" render={msg => <p className={'error text-red-600'}>{msg}</p>} />

                                    </div>

                                    <div>
                                        <Button className={'bg-green-500 py-[6px] border-green-500 w-full'} type="submit" disabled={isSubmitting}>
                                            Get Started
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
        </>
    );
}