import React, { useState, useEffect }  from 'react';
import {login, register, fullProfile, setLoading} from '../reducers/user';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, Navigate, Link } from "react-router-dom";
import { Button } from '../components/Button';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';
import zxcvbn from 'zxcvbn';
import linkedinButton from '../../images/linkedin-login-button.png';

export function FullProfile(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const [registerError, setRegisterError] = useState(null);

    return (
        <>
            <Header />

            {user.isLoading && <Loading />}

            {!user.isLoading && <section className={'py-20 min-h-[50vh]'}>
                <div className={'container mx-auto'}>

                    <div className="lg:hidden px-5 text-center">
                        <h1 className="text-2xl text-center mb-10">FinOps Assessment Tool</h1>
                        <p>The assessment tool is currently in beta and is therefore only available on desktop-based devices.</p>
                        <p>Please visit again from your laptop/computer. </p>

                        <p><a className={'inline-flex justify-center py-2 px-2 sm:px-4 text-sm leading-relaxed font-medium rounded-sm text-white border border-green-500 font-normal bg-green-500 hover:bg-green-600 hover:border-green-600 transition-colors duration-200 mt-10'} href={'https://finops.org'}>Go back to finops.org</a></p>
                    </div>

                    <div className="hidden lg:grid px-20 grid-cols-4 gap-9">
                        <div className="col-span-4">
                            <h1 className="text-3xl text-center mb-10">Complete Your Profile</h1>
                        </div>

                        <div className="p-10 col-span-2 col-start-2 bg-white rounded-lg shadow-xl">
                            {registerError && <div className={'mt-5 text-red-700'} role="alert">{ registerError }</div>}

                            <Formik
                                initialValues={{
                                    email: user.email ? user.email : '',
                                    company: user.company ? user.company : '',
                                    industry_uuid: user.industry_uuid ? user.industry_uuid : '',
                                }}
                                validate={values => {
                                    const errors = {};
                                    if (!values.company) {
                                        errors.company = 'Required';
                                    }

                                    if (!values.industry_uuid) {
                                        errors.industry_uuid = 'Required';
                                    }

                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setRegisterError(null);
                                    window.scrollTo(0, 0);
                                    setSubmitting(false);
                                    dispatch(setLoading(true));
                                    dispatch(fullProfile(values)).then((response) => {
                                        if(response.type !== 'user/register/rejected') {
                                            setTimeout(() => {
                                                window.location.href = '/';
                                            }, 100)
                                        }
                                        else {
                                            setRegisterError(response.payload.message);
                                        }
                                    });
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form>

                                        <div>
                                            <label className={"mb-2 block"}><strong>Company</strong></label>
                                            <Field placeholder={'Acme Co.'} type="text" className={'border rounded-sm border-gray-600'} name="company" />
                                            <ErrorMessage className={'text-sm text-red-700 mt-1'} name="company" component="div" />
                                        </div>

                                        <div className={"mt-5"}>
                                            <label className={"mb-2 block"}><strong>Industry</strong></label>
                                            <Field placeholder={'Acme Co.'} as="select" className={'border rounded-sm border-gray-600'} name="industry_uuid">
                                                <option value=""></option>
                                                <option value="b24579aa-b94f-11ed-afa1-0242ac120002">Automotive</option>
                                                <option value="b2457af4-b94f-11ed-afa1-0242ac120002">Business Services
                                                    (accounting, consulting, legal, etc.)
                                                </option>
                                                <option value="b2457bee-b94f-11ed-afa1-0242ac120002">Consumer Packaged
                                                    Goods
                                                </option>
                                                <option
                                                    value="b2457cd4-b94f-11ed-afa1-0242ac120002">Construction/Engineering
                                                </option>
                                                <option value="b2457dba-b94f-11ed-afa1-0242ac120002">Education
                                                    (K-12/Primary/Secondary)
                                                </option>
                                                <option value="b2457f04-b94f-11ed-afa1-0242ac120002">Education
                                                    (College/University/Workforce)
                                                </option>
                                                <option value="b24580f8-b94f-11ed-afa1-0242ac120002">Energy</option>
                                                <option value="b245829c-b94f-11ed-afa1-0242ac120002">Financial Services
                                                    (Banking/Insurance/Securities)
                                                </option>
                                                <option value="b2458576-b94f-11ed-afa1-0242ac120002">Government
                                                    (Federal/National)
                                                </option>
                                                <option value="b245872e-b94f-11ed-afa1-0242ac120002">Government
                                                    (State/Province/County/Municipal/other local government)
                                                </option>
                                                <option value="b24588b4-b94f-11ed-afa1-0242ac120002">Health Care
                                                </option>
                                                <option value="b2458a6c-b94f-11ed-afa1-0242ac120002">Information
                                                    technology (IT vendor or service provider)
                                                </option>
                                                <option value="b2458bb6-b94f-11ed-afa1-0242ac120002">Life Sciences
                                                    (biotech, pharmaceuticals, etc.)
                                                </option>
                                                <option value="b2458d6e-b94f-11ed-afa1-0242ac120002">Manufacturing
                                                    (discrete or process)
                                                </option>
                                                <option value="b2458eea-b94f-11ed-afa1-0242ac120002">Media (broadcast
                                                    communications, entertainment, publishing, web site, social
                                                    networking, etc.)
                                                </option>
                                                <option value="b2459098-b94f-11ed-afa1-0242ac120002">Oil & Gas</option>
                                                <option value="b24597b4-b94f-11ed-afa1-0242ac120002">Professional
                                                    Services
                                                </option>
                                                <option value="b245994e-b94f-11ed-afa1-0242ac120002">Retail &
                                                    Wholesale
                                                </option>
                                                <option value="b2459aca-b94f-11ed-afa1-0242ac120002">Telecommunications
                                                    / Internet Service Provider (ISP) / Web Hosting
                                                </option>
                                                <option value="b2459c32-b94f-11ed-afa1-0242ac120002">Transportation &
                                                    Logistics (other than automotive)
                                                </option>
                                                <option value="b2459dfe-b94f-11ed-afa1-0242ac120002">Travel and
                                                    Hospitality
                                                </option>
                                                <option value="b2459ffc-b94f-11ed-afa1-0242ac120002">Utilities (other
                                                    than energy)
                                                </option>
                                                <option value="b2459ffc-b94f-11ed-bfa1-0242ac120002">Other
                                                </option>
                                            </Field>
                                            <ErrorMessage className={'text-sm text-red-700 mt-1'} name="company" component="div" />
                                        </div>

                                        <div className={"mt-5"}>
                                            <p>By submitting the form you expressly agree to the <a className={'text-green-600'} href={"https://lfprojects.org/"} target={'_blank'}>Linux Foundation policies</a>.</p>
                                        </div>

                                        <div className={'mt-10'}>
                                            <Button className={'bg-green-500 border-green-500'} type="submit" disabled={isSubmitting}>
                                                Get Started
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    );
}