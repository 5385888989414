import React, { useState, useEffect }  from 'react';
import {setGroup, loginWithLinkedIn} from '../reducers/user';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, Navigate } from "react-router-dom";
import { Button } from '../components/Button';
import { Header } from '../components/Header'
import { Loading } from '../components/Loading'

export function Auth(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if(code && state){
            dispatch(loginWithLinkedIn({
                code: code, state: state
            })).then((response) => {
                if(response.type !== 'user/loginWithLinkedIn/rejected') {
                    if (response.payload.company && response.payload.industry_uuid){
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 100)
                    }
                    else {
                        setTimeout(() => {
                            window.location.href = '/full-profile';
                        }, 100)
                    }
                }
                else {
                    // setLoginError(response.payload.message);
                }
            });;
        }
    }, []);

    return (
        <>
            <Header />

            <Loading />
        </>
    );
}