import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { redirect } from "react-router-dom";

export function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token };
    } else {
        return {};
    }
}

let API_URL = 'http://localhost/api';
if(process.env.NODE_ENV === 'prod') {
    API_URL = 'https://asessment-api.finops.org/api';
}

const user = JSON.parse(localStorage.getItem("user"));

let initialLoggedOutState = {
    super_user: false,
    token: null,
    isLoggedIn: false,
    email: '',
    first_name: null,
    last_name: null,
    group: '',
    capabilities: [],
    company: '',
    industry_uuid: '',
    assessments: [],
    error: null,
    showVerificationField: false,
    isLoading: false,
    showVerificationSent: false
};

let initialState = initialLoggedOutState;
if(user){
    initialState.email = user.email
    initialState.first_name = user.first_name
    initialState.last_name = user.last_name
    initialState.token = user.token
    initialState.assessments = user.assessments
    initialState.super_user = user.super_user
    initialState.isLoggedIn = true
    initialState.company = user.company
    initialState.industry_uuid = user.industry_uuid
}

export const getAdminAssessments = createAsyncThunk('user/getAdminAssessments', async () => {
    return await axios.get(API_URL + '/assessments', {headers: authHeader()}).then((response) => {
        const assessments = response.data.data;

        return assessments
    });
});

export const deleteAssessment = createAsyncThunk('user/getAdminAssessments', async (uuid, {dispatch}) => {
    return await axios.delete(API_URL + '/assessments/' + uuid, {headers: authHeader()}).then((response) => {
        return dispatch(getAssessments());
    });
});

export const getAssessments = createAsyncThunk('user/getAssessments', async () => {
    return await axios.get(API_URL + '/user-assessments', {headers: authHeader()}).then((response) => {
        const assessments = response.data.data;

        return assessments
    });
});

export const loginWithLinkedIn = createAsyncThunk('user/loginWithLinkedIn', async (values, {rejectWithValue}) => {
    try {
        return await axios.get(API_URL + '/auth/linkedin/callback?code=' + values.code + '&state=' + values.state ).then((response) => {
            const state = {
                token: response.data.token,
                isLoggedIn: true,
                email: response.data.user.email,
                first_name: response.data.user.first_name,
                last_name: response.data.user.last_name,
                company: response.data.user.company,
                industry_uuid: response.data.user.industry_uuid,
                group: '',
                assessments: response.data.user.assessments,
                capabilities: [],
                super_user: response.data.user.super_user,
                showVerificationField: false
            }

            localStorage.setItem("user", JSON.stringify(state));

            return state
        });
    } catch (err) {
        if (!err.response) {
            throw err
        }

        return rejectWithValue(err.response.data)
    }
});

export const login = createAsyncThunk('user/login', async (values, {rejectWithValue}) => {
    try {
        return await axios.post(API_URL + '/login', values).then((response) => {
            const state = {
                token: response.data.token,
                isLoggedIn: true,
                email: response.data.user.email,
                first_name: response.data.user.first_name,
                last_name: response.data.user.last_name,
                group: '',
                assessments: response.data.user.assessments,
                capabilities: [],
                super_user: response.data.user.super_user,
                showVerificationField: false,
                company: response.data.user.company,
                industry_uuid: response.data.user.industry_uuid,
            }

            localStorage.setItem("user", JSON.stringify(state));

            return state
        });
    } catch (err) {
        if (!err.response) {
            throw err
        }

        return rejectWithValue(err.response.data)
    }
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (values, {rejectWithValue}) => {
    if(values.hash && values.expires && values.signature && values.user) {
        const query = new URLSearchParams(values).toString();

        return await axios.get(API_URL + '/users/verify/' + values.user + query ).then((response) => {


            return state
        });
    }
    else{
        return await axios.post(API_URL + '/forgot', values).then((response) => {
            const state = {
                showVerificationField: true
            }

            return state
        });
    }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async (values, {rejectWithValue, dispatch}) => {
    const query = new URLSearchParams(values).toString();
    try {
        return await axios.get(API_URL + '/users/verify/' + values.user + '?' + query).then((verifyResponse) => {
            if (verifyResponse.data.uuid) {
                return axios.put(API_URL + '/users/' + values.user + '/password', values).then((response) => {
                    return dispatch(login({email: verifyResponse.data.email, password: values.password})).then(() => {
                        window.location.href = '/';
                    });
                });
            } else {
                return rejectWithValue('There was an error verifying your email address, please contact support.'); // ERROR
            }
        });
    } catch (err) {
        if (!err.response) {
            throw err
        }

        return rejectWithValue(err.response.data)
    }
});

export const register = createAsyncThunk('user/register', async (values, {dispatch, rejectWithValue}) => {
    try {
        return await axios.put(API_URL + '/register', values).then(() => {
           return dispatch(login({email: values.email, password: values.password}));
        });
    } catch (err) {
        if (!err.response) {
            throw err
        }

        return rejectWithValue(err.response.data)
    }
})

export const fullProfile = createAsyncThunk('user/fullProfile', async (values, {dispatch, rejectWithValue}) => {
    try {
        return await axios.post(API_URL + '/user', values, {headers: authHeader()}).then((response) => {
            const state = {
                token: response.data.data.token,
                isLoggedIn: true,
                email: response.data.data.email,
                first_name: response.data.data.first_name,
                last_name: response.data.data.last_name,
                group: '',
                assessments: response.data.data.assessments,
                capabilities: [],
                super_user: response.data.data.super_user,
                showVerificationField: false,
                company: response.data.data.company,
                industry_uuid: response.data.data.industry_uuid,
            }

            return state;
        });
    } catch (err) {
        if (!err.response) {
            throw err
        }

        return rejectWithValue(err.response.data)
    }
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout(state, action) {
            state = initialLoggedOutState;
            localStorage.removeItem("user");
        },
        setLoading: (state, action) => {
            state.isLoading = true;
        },
        setGroup: (state, action) => {
            state.group = action.payload;
        },
        setLensScore: (state, action) => {
            let lens = action.payload.lens;

            state.capabilities[action.payload.index].lenses[lens].score = action.payload.value;

            state.capabilities.forEach((capability) => {
                let total = 0;

                total += parseInt(capability.lenses.knowledge.score);
                total += parseInt(capability.lenses.process.score);
                total += parseInt(capability.lenses.metrics.score);
                total += parseInt(capability.lenses.adoption.score);
                total += parseInt(capability.lenses.automation.score);

                capability.totalScore = total;
            })
        },
        setLensTargetScore: (state, action) => {
            let lens = action.payload.lens;

            state.capabilities[action.payload.index].lenses[lens].targetScore = action.payload.value;

            state.capabilities.forEach((capability) => {
                let total = 0;

                total += parseInt(capability.lenses.knowledge.targetScore);
                total += parseInt(capability.lenses.process.targetScore);
                total += parseInt(capability.lenses.metrics.targetScore);
                total += parseInt(capability.lenses.adoption.targetScore);
                total += parseInt(capability.lenses.automation.targetScore);

                capability.totalTargetScore = total;
            })
        },
        toggleComment: (state, action) => {
            state.capabilities[action.payload.index].lenses[action.payload.lens].commentOpen = !state.capabilities[action.payload.index].lenses[action.payload.lens].commentOpen;
        },
        setComment: (state, action) => {
            state.capabilities[action.payload.index].lenses[action.payload.lens].comment = action.payload.comment;
        },
        toggleCapability: (state, action) => {
            if(state.capabilities.find(capability => capability.objectID === action.payload.objectID)){
                for( var i = 0; i < state.capabilities.length; i++){
                    if ( state.capabilities[i].objectID === action.payload.objectID ){
                        state.capabilities.splice(i, 1);
                    }
                }
            }
            else {
                let cap = action.payload;
                cap.targetScore = {
                    knowledge: "0",
                    process: "0",
                    metrics: "0",
                    adoption: "0",
                    automation: "0"
                }
                cap.totalTargetScore = "0";
                cap.score = {
                    knowledge: "0",
                    process: "0",
                    metrics: "0",
                    adoption: "0",
                    automation: "0"
                }
                cap.totalScore = "0";

                cap.lenses = {
                    knowledge: {
                        title: 'Knowledge',
                        slug: 'knowledge',
                        commentOpen: false,
                        comment: '',
                        score: "0",
                        targetScore: "0"
                    },
                    process: {
                        title: 'Process',
                        slug: 'process',
                        commentOpen: false,
                        comment: '',
                        score: "0",
                        targetScore: "0"
                    },
                    metrics: {
                        title: 'Metrics',
                        slug: 'metrics',
                        commentOpen: false,
                        comment: '',
                        score: "0",
                        targetScore: "0"
                    },
                    adoption: {
                        title: 'Adoption',
                        slug: 'adoption',
                        commentOpen: false,
                        comment: '',
                        score: "0",
                        targetScore: "0"
                    },
                    automation: {
                        title: 'Automation',
                        slug: 'automation',
                        commentOpen: false,
                        comment: '',
                        score: "0",
                        targetScore: "0"
                    }
                };

                state.capabilities[state.capabilities.length] = cap;
            }

            // state.capabilities = state.capabilities.slice(0,3)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.isLoading = true;
            return state;
        }).addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;

            console.log('State', state.company);

            return state;
        }).addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            return state;
        }).addCase(forgotPassword.pending, (state, action) => {
            state.isLoading = true;
            return state;
        }).addCase(forgotPassword.fulfilled, (state, action) => {
            action.payload.isLoading = false;
            action.payload.showVerificationSent = true;
            return state = action.payload;
        }).addCase(forgotPassword.rejected, (state, action) => {
            state.isLoading = false;
            return state;
        }).addCase(resetPassword.pending, (state, action) => {
            state.isLoading = true;
            return state;
        }).addCase(resetPassword.fulfilled, (state, action) => {
            action.payload.isLoading = false;
            return state = action.payload;
        }).addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false;
            return state;
        }).addCase(register.pending, (state, action) => {
            state.isLoading = true;
            return state;
        }).addCase(register.fulfilled, (state, action) => {
            action.payload.isLoading = false;
            return state = action.payload;
        }).addCase(register.rejected, (state, action) => {
            state.isLoading = false;
            return state;
        }).addCase(getAssessments.fulfilled, (state, action) => {
            state.assessments = action.payload;

            localStorage.setItem("user", JSON.stringify(state));

            return state;
        }).addCase(getAdminAssessments.fulfilled, (state, action) => {
            state.adminAssessments = action.payload;

            localStorage.setItem("user", JSON.stringify(state));

            return state;
        }).addCase(loginWithLinkedIn.fulfilled, (state, action) => {
            state.isLoading = false;
            return state;
        }).addCase(fullProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.company = action.payload.company;
            state.industry_uuid = action.payload.industry_uuid;

            localStorage.setItem("user", JSON.stringify(state));
            
            return state;
        }).addCase(fullProfile.rejected, (state, action) => {
            state.isLoading = false;
            return state;
        })
    }
})

export const { setGroup, toggleCapability, setLoading, logout, setLensScore, setLensTargetScore, toggleComment, setComment } = userSlice.actions

export default userSlice.reducer