import React from "react";
import {openAccordionItem} from "../reducers/lens";
import {useDispatch, useSelector} from "react-redux";

export const LensAccordion = () => {
    const dispatch = useDispatch();
    const lenses = useSelector(state => state.lenses);

    return (
        <div className={'bg-white rounded-sm'}>
            {Object.keys(lenses).map((lensKey, lensIndex) => {
                const lens = lenses[lensKey];
                return (
                    <div className={'border-gray-200 ' + (lensIndex+1 !== Object.keys(lenses).length ? ' border-b' : '')} key={lensIndex}>
                        <h2 className="mb-0" id="headingOne">
                            <button onClick={() => dispatch(openAccordionItem(lensKey))} className="group text-gray-800 relative flex w-full justify-between items-center rounded-t-[15px] border-0 bg-white py-4 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none">
                                {lens.title}
                                <span className={"ml-auto h-5 w-5 flex items-center justify-center shrink-0 fill-gray-600 origin-center" + (lens.isAccordionOpen ? ' rotate-[-180deg]' : '')}>
                                      <svg
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="h-6 w-6">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                      </svg>
                                </span>
                            </button>
                        </h2>
                        {lens.isAccordionOpen &&
                            <div className="">
                                {lens.scores.map((score, index) => {
                                    return (
                                        <div className={(index+1 !== lens.scores.length ? 'mb-5' : '')}>
                                            <h3 className={'text-sm mb-1'}>{score.maturity} - {score.heading}</h3>
                                            <p className={'text-sm mb-0'}>{score.description}</p>
                                        </div>
                                    )
                                })}

                                <a className={'my-3 text-sm block text-green-600'} target={'_blank'} href="https://www.finops.org/wg/finops-assessment/#lens-1-knowledge">Read more</a>
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    )
}