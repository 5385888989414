import React, { useState, useEffect }  from 'react';
import {setGroup, setComment, toggleCapability, setLensScore, toggleComment} from '../reducers/user';
import {saveAssessment, setLoading} from '../reducers/assessment';
import { useSelector, useDispatch } from 'react-redux'
import {Formik, Form, select, ErrorMessage, Field} from 'formik';
import { useNavigate, Navigate } from "react-router-dom";
import { Formik, Form, select, ErrorMessage } from 'formik';
import {Button} from "../components/Button";
import { Header } from '../components/Header'
import { LensAccordion } from '../components/LensAccordion'
import {openAccordionItem} from "../reducers/lens";
import {ProgressIndicator} from "../components/ProgressIndicator";
import {Loading} from "../components/Loading";

const ChatIcon = ({hasComment}) => {

    return (
        <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460 430">
            <g fill="none">
                <path fill="#000" d="M47.922 1.367c-17.7 4.7-33.5 17.5-41.5 33.9-6.9 13.9-6.6 6.9-6.3 134.1l.3 114.9 2.6 6.7c6.2 15.9 17.1 28.6 30.6 35.6 15.5 8 8.6 7.5 112.5 8.1l92.8.6 45.7 45.7c31.3 31.3 47 46.2 49.5 47.3 7.7 3.1 16.8 1.4 22-4.1 5.6-5.9 5.8-8.1 5.8-50.4v-38.7l22.3-.6c24.2-.6 29.4-1.5 40.1-6.9 13.2-6.6 23.6-17.3 29.7-30.3 6.2-13.2 6-7.1 5.7-133.1l-.3-114.9-2.6-6.7c-7.4-19.1-20.6-32.3-39.7-39.7l-6.7-2.6-178.5-.2c-153.2-.2-179.3 0-184 1.3Z"/>
                <path fill="#fff" d="M407.722 29.967c10 4 18.1 12.1 21.9 21.8 1.7 4.2 1.8 11.8 2.1 110.5.2 65.8-.1 108.4-.7 112.3-1.3 8.6-4.5 14.7-10.7 20.9-9 8.8-12.3 9.6-42.4 10.3-15.2.3-26.5 1-28 1.7-6 2.6-10.9 7.3-13.6 13l-2.9 5.8-.3 31.4-.3 31.5-39.2-39.2c-31-30.9-40.2-39.6-44.2-41.4l-5-2.3-94-.5-94-.5-6-2.4c-11-4.5-19.5-15.4-21.5-27.7-1.3-7.9-1.3-208.9 0-216.8 2.4-14.8 13.7-26.7 28.1-29.5 2.4-.5 81.4-.8 175.4-.7 158.8.1 171.3.2 175.3 1.8Z"/>
                <path fill="#000" d="M119.422 95.667c-4.4 1.9-7 6-7 11.2 0 4.2.5 5.3 3.3 8.1l3.2 3.3h222l3.2-3.3c2.8-2.8 3.3-3.9 3.3-8.2 0-4.3-.5-5.4-3.3-8.2l-3.2-3.3-109.8-.2c-60.3-.1-110.6.2-111.7.6Zm0 60c-4.4 1.9-7 6-7 11.2 0 4.2.5 5.3 3.3 8.1l3.2 3.3h222l3.2-3.3c2.8-2.8 3.3-3.9 3.3-8.2 0-4.3-.5-5.4-3.3-8.2l-3.2-3.3-109.8-.2c-60.3-.1-110.6.2-111.7.6Zm0 60c-6.1 2.6-8.9 10.3-6 16.4 3.4 7.2-4.1 6.8 117.9 6.5l109.6-.3 3.2-3.3c2.8-2.8 3.3-3.9 3.3-8.2 0-4.3-.5-5.4-3.3-8.2l-3.2-3.3-109.8-.2c-60.3-.1-110.6.2-111.7.6Z"/>
            </g>
        </svg>
    )
}

export const ScoreSelect = ({initialValue, capability, lens, index}) => {
    const dispatch = useDispatch();

    return (
        <select value={initialValue} onChange={(value) => {
            dispatch(setLensScore({
                index,
                lens,
                value: value.target.value
            }));
        }} className={"p-2"}>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
        </select>
    )
}

export function Measurement(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const capabilities = useSelector(state => state.user.capabilities);
    const lenses = useSelector(state => state.lenses);
    const user = useSelector(state => state.user);
    const assessment = useSelector(state => state.assessment);

    if(!user.group.length || !Object.keys(user.capabilities).length){
        return <Navigate to={'/assessment'} />
    }

    const beforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
    }

    useEffect(() => {
        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, []);

    return (
        <>
            <Header />

            {assessment.isLoading && <Loading />}

            {!assessment.isLoading &&
                <section className={'pt-20 pb-[4px]'}>
                    <div className={'container pb-20 mx-auto'}>
                        <h1 className={'text-3xl mb-5'}>Step 4: Measure Capabilities Current Scores</h1>
                        <div className="grid grid-cols-12 gap-9">
                            <div className="col-span-8">
                                <p>Use the chart below to measure each lens per capability and provide evidence as to why
                                    you selected that score.</p>

                                <p>Examples of evidence would be past successes, gaps, and future ambitions.</p>
                            </div>
                            <div className="col-span-4">
                                <div className='embed-container'>
                                    <iframe src='https://www.youtube.com/embed/LkTk63W5Acc' frameBorder='0'
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <hr className={'mt-10 mb-10'}/>

                        <div className="grid grid-cols-12 gap-9">
                            <div className="col-span-9">
                                <div className="bg-white rounded-lg shadow-xl p-10">
                                    <h3 className={'text-xl mb-5'}>Assessment Scores for {user.group}</h3>

                                    <table className={'w-full table-fixed'}>
                                        <thead>
                                        <tr>
                                            <th/>
                                            <th className={"small"}>Knowledge <span
                                                onClick={() => dispatch(openAccordionItem('knowledge'))}
                                                className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Process <span
                                                onClick={() => dispatch(openAccordionItem('process'))}
                                                className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Metrics <span
                                                onClick={() => dispatch(openAccordionItem('metrics'))}
                                                className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Adoption <span
                                                onClick={() => dispatch(openAccordionItem('adoption'))}
                                                className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Automation <span
                                                onClick={() => dispatch(openAccordionItem('automation'))}
                                                className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small xs"}>Total</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {capabilities.map((capability, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th>
                                                        <strong className={'font-semibold'}
                                                                dangerouslySetInnerHTML={{__html: capability.title}}/>
                                                    </th>
                                                    {Object.keys(capability.lenses).map((lensKey, lensIndex) => {
                                                        const lens = capability.lenses[lensKey];

                                                        return (
                                                            <td key={lensIndex}>
                                                                <div className="relative">
                                                                    <select tabIndex={index + lensIndex}
                                                                            value={capability.lenses[lens.slug].score}
                                                                            onChange={(value) => {
                                                                                dispatch(setLensScore({
                                                                                    index,
                                                                                    lens: lens.slug,
                                                                                    value: value.target.value
                                                                                }));

                                                                                dispatch(toggleComment({
                                                                                    index,
                                                                                    lens: lens.slug
                                                                                }))
                                                                            }} className={"p-2 bg-gray-100 pr-10"}>
                                                                        {lenses[lens.slug].scores.map((lens, index) => {
                                                                            return <option key={index}
                                                                                           value={index}>{index} / {lens.heading}</option>
                                                                        })}
                                                                    </select>

                                                                    <button onClick={() => dispatch(toggleComment({
                                                                        index,
                                                                        lens: lens.slug
                                                                    }))}
                                                                            className={"absolute h-full block top-0 px-[8px] right-0 " + (lens.comment.length > 0 ? 'bg-gray-500' : 'bg-green-500')}>
                                                                        <ChatIcon hasComment={lens.comment.length > 0}/>
                                                                    </button>
                                                                </div>

                                                                {lens.commentOpen &&
                                                                <>
                                                                    <div onClick={() => dispatch(toggleComment({
                                                                        index,
                                                                        lens: lens.slug
                                                                    }))}
                                                                         className="bg-gray-800 opacity-50 h-[100vh] w-[100vw] fixed top-0 left-0 z-[30]"></div>

                                                                    <div
                                                                        className="fixed z-50 w-1/2 m-auto left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] p-6 bg-white overflow-y-scroll card open-card fixed inset-x-0 inset-y-0 z-[31] card-full bg-white rounded-lg">
                                                                        <Formik
                                                                            initialValues={{comment: lens.comment}}
                                                                            validate={values => {
                                                                                const errors = {};
                                                                                if (!values.comment) {
                                                                                    errors.comment = 'Required';
                                                                                }
                                                                                return errors;
                                                                            }}
                                                                            onSubmit={(values, {setSubmitting}) => {
                                                                                dispatch(setComment({
                                                                                    index,
                                                                                    lens: lens.slug,
                                                                                    comment: values.comment
                                                                                }));
                                                                                dispatch(toggleComment({
                                                                                    index,
                                                                                    lens: lens.slug
                                                                                }));
                                                                                setSubmitting(false);
                                                                            }}
                                                                        >
                                                                            {({isSubmitting}) => (
                                                                                <Form
                                                                                    className={'text-start h-full flex flex-col'}>
                                                                                    <label className={"mb-2 text-xl block"}><strong>Evidence
                                                                                        on <span
                                                                                            dangerouslySetInnerHTML={{__html: capability.title}}/> - {lens.title}
                                                                                    </strong></label>

                                                                                    <div className={'grow pb-2'}>
                                                                                        <Field as="textarea" rows={5}
                                                                                               className={'w-full border p-3 h-full'}
                                                                                               name="comment"/>
                                                                                        <ErrorMessage name="email"
                                                                                                      component="div"/>
                                                                                    </div>

                                                                                    <div
                                                                                        className={'mt-3 flex w-full justify-end'}>
                                                                                        <button
                                                                                            onClick={() => dispatch(toggleComment({
                                                                                                index,
                                                                                                lens: lens.slug
                                                                                            }))}
                                                                                            className="border border-gray-700 text-gray-700 rounded-lg px-10 py-1 hover:bg-gray-700 hover:text-white">Close
                                                                                        </button>
                                                                                        <button
                                                                                            className={'ml-4 border border-blue text-blue rounded-lg px-10 py-1 hover:bg-blue hover:text-white'}
                                                                                            type="submit"
                                                                                            disabled={isSubmitting}>Save
                                                                                        </button>
                                                                                    </div>
                                                                                </Form>
                                                                            )}
                                                                        </Formik>
                                                                    </div>
                                                                </>
                                                                }
                                                            </td>
                                                        )
                                                    })}
                                                    <td>
                                                        <input className={'cursor-default bg-gray-200'}
                                                               value={capability.totalScore} type="text" readOnly={true}/>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-span-3">
                                <div className="bg-white rounded-lg shadow-xl p-10">
                                    <LensAccordion/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ProgressIndicator className={'progress-3'} step={'Step 3 of 4 - Current Scores'}>
                        <Button className={'bg-gray-700 border-gray-700'} onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/target-score')
                        }}>Prev</Button>
                        <Button className={'ml-5 bg-green-600 border-green-600'} onClick={() => {
                            if (window.confirm('Are you sure you want to complete the assessment? Once saved, it cannot be edited.')) {
                                window.scrollTo(0, 0);
                                dispatch(setLoading());
                                dispatch(saveAssessment()).then((response) => {
                                    if (response.type === 'assessment/saveAssessment/fulfilled') {
                                        navigate('/summary/' + response.payload.uuid);
                                    }
                                });
                            }
                        }}>Complete Assessment</Button>
                    </ProgressIndicator>
                </section>
            }
        </>
    );
}