import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import userReducer from './reducers/user'
import assessmentReducer from './reducers/assessment'
import lensReducer from './reducers/lens'

export default configureStore({
    reducer: {
        user: userReducer,
        assessment: assessmentReducer,
        lenses: lensReducer
    },
})