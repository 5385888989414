import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { redirect } from "react-router-dom";

export function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token };
    } else {
        return {};
    }
}

let API_URL = 'http://localhost/api';
if(process.env.NODE_ENV === 'prod') {
    API_URL = 'https://asessment-api.finops.org/api';
}

let initialState = {
    isLoading: false
};

export const getCapability = createAsyncThunk('assessment/getCapability', async (id, {getState}) => {
    return await axios.get('https://finops.org/wp-json/wp/v2/capability/' + id + '?asd').then((response) => {
        return response.data;
    });
});

export const getAssessment = createAsyncThunk('assessment/getAssessment', async (uuid) => {
    return await axios.get(API_URL + '/assessments/' + uuid).then((response) => {
        const state = response.data.data;

        state.capabilities.forEach((item) => {
            item.show_comments = false;
        });

        return state
    });
});

export const saveAssessment = createAsyncThunk('assessment/saveAssessment', async (arg, {getState}) => {
    const currentState = getState();

    let data = {
        group_name: getState().user.group,
        capabilities: getState().user.capabilities
    };

    return await axios.post(API_URL + '/assessments', data, { headers: authHeader() }).then((response) => {
        const state = response.data.data;

        state.capabilities.forEach((item) => {
            item.show_comments = false;
        });

        return state
    });
});

export const assessmentSlice = createSlice({
    name: 'assessment',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = true
        },
        toggleCapabilityComment: (state, action) => {
            state.capabilities[action.payload.index].show_comments = !state.capabilities[action.payload.index].show_comments
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAssessment.fulfilled, (state, action) => {
            state = action.payload;
            state.isLoading = false;

            return state;
        }).addCase(saveAssessment.fulfilled, (state, action) => {
            state = action.payload;
            state.isLoading = false;

            return state;
        }).addCase(getCapability.fulfilled, (state, action) => {
            state.capabilities.forEach((item) => {
                if (item.capability === action.payload.id) {
                    item.full = action.payload;
                }
            });

            return state;
        })
    }
})

export const { setLoading, toggleCapabilityComment } = assessmentSlice.actions

export default assessmentSlice.reducer