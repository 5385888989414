import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { logout } from '../reducers/user'

export function Header(){
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const doLogout = () => {
        dispatch(logout());
        window.location.href = '/';
    }
    
    return (
        <>
            <div className="px-6 pb-6 pt-6 lg:px-8 bg-white">
                <div>
                    <nav className="flex h-9 items-center justify-between" aria-label="Global">
                        <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
                            <a href={"/"} className="-m-1.5 p-1.5">
                                <span className="sr-only">FinOps Assessment Tool</span>

                                <img width="162" src="https://www.finops.org/wp-content/themes/finops-foundation/assets/images/finops-logo_blue.svg" alt="" />
                            </a>
                        </div>

                        <div className="mx-auto hidden md:block">
                            {!!user && user.first_name &&
                                <p className="inline-flex py-2 mr-5">Welcome, {user.first_name} ({user.email}) <button className={'ml-5 text-green-600'} onClick={() => doLogout()}>Logout</button></p>
                            }

                            {!!user && user.super_user === 1 &&
                                <Link to={'/admin/'} className="inline-flex justify-center py-2 px-2 sm:px-4 border text-sm leading-relaxed font-medium rounded-sm text-green-500 border border-green-500 hover:text-white font-normal hover:bg-gray-600 hover:border-gray-600 transition-colors duration-200 mr-2">
                                    Admin
                                </Link>
                            }

                            {!!user && user.first_name &&
                                <Link to={'/'}
                                      className="inline-flex justify-center py-2 px-2 sm:px-4 text-sm leading-relaxed font-medium rounded-sm text-white border border-green-500 font-normal bg-green-500 hover:bg-green-600 hover:border-green-600 transition-colors duration-200 mr-2">
                                    Dashboard
                                </Link>
                            }
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}