import React, { useState, useEffect }  from 'react';
import {setGroup, setComment, toggleCapability, setLensScore, toggleComment} from '../reducers/user';
import {toggleCapabilityComment, getCapability} from '../reducers/assessment';
import { useSelector, useDispatch } from 'react-redux'
import {Formik, Form, select, ErrorMessage, Field} from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, select, ErrorMessage } from 'formik';
import {Button} from "../components/Button";
import _  from 'underscore';
import { Header } from '../components/Header';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { Loading } from '../components/Loading';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const generatePDF = () => {
    var element = document.getElementById('exportPDF');
    var opt = {
        margin:       1,
        filename:     'FinOpsAssessmentToolSummary.pdf',
        html2canvas:  { scale: 2 },
    };

    html2pdf().set(opt).from(element).save();
}

const copyToClipboard = () => {
    let container = document.getElementById('copyContainer');
    const originalHtml = container.innerHTML;

    let aux = document.createElement("div");
    aux.setAttribute("contentEditable", true);
    aux.innerHTML = window.location.href;
    aux.style.position = "absolute";
    aux.style.left = "-9999px";
    aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)");

    container.appendChild(aux);
    aux.focus();
    document.execCommand("copy");
    container.removeChild(aux);

    container.innerText = 'Copied!';
    setTimeout(() => {
        container.innerHTML = originalHtml;
    }, 1500)

    return false;
}

const getRelativeVars = (capability) => {

    let relativeScore = (capability.total_score > capability.total_target_score ? capability.total_target_score : capability.total_score) - capability.total_target_score;
    let relativeScorePercentage = Math.round((100 * (capability.total_score > capability.total_target_score ? capability.total_target_score : capability.total_score) / capability.total_target_score));

    if(isNaN(relativeScorePercentage)){
        relativeScorePercentage = 0;
    }

    let relativeBackground = 'bg-white';
    if(parseInt(relativeScorePercentage) > 80) {
        relativeScore = '+' + relativeScore;
        relativeBackground = 'bg-green-500'
    }
    else if(parseInt(relativeScorePercentage) < 40){
        relativeBackground = 'bg-red-400';
    }
    else {
        relativeBackground = 'bg-yellow';
    }

    if(!isFinite(relativeScorePercentage)){
        relativeScorePercentage = '>' + 100;
    }

    return {
        relativeScore,
        relativeScorePercentage,
        relativeBackground
    }
}

const CapabilityCardContent = ({capability}) => {
    const dispatch = useDispatch();
    const {relativeBackground, relativeScore, relativeScorePercentage} = getRelativeVars(capability);

    useEffect(() => {
        dispatch(getCapability(capability.capability));
    }, []);

    return (
        <>
            <h3 className="text-2xl mb-3 md:mt-5 mt-3">{capability.title}</h3>

            {capability.full && capability.full.excerpt &&
                <p className="excerpt mb-8">
                    {capability.full.excerpt.rendered.replace(/(<([^>]+)>)/gi, "")} <a className={'text-green-600'} href={capability.full.link} target="_blank">Read more</a>
                </p>
            }

            <table className={'w-full table-fixed'}>
                <thead>
                <tr>
                    <th className={"small"}>Score</th>
                    <th className={"small"}>Scope Target</th>
                    <th className={"small"}>Relative to Target</th>
                    <th className={"small"}>% Progress</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>
                        <input className={'cursor-default bg-gray-200'} value={capability.total_score} type="text" readOnly={true} />
                    </td>
                    <td>
                        <input className={'cursor-default bg-gray-200'} value={capability.total_target_score} type="text" readOnly={true} />
                    </td>
                    <td>
                        <input className={'cursor-default ' + relativeBackground}  type={'text'} readOnly={true} value={relativeScore} />
                    </td>
                    <td>
                        <input className={'cursor-default ' + relativeBackground}  type={'text'} readOnly={true} value={relativeScorePercentage + '%'} />
                    </td>
                </tr>
                </tbody>
            </table>

            {capability.full && capability.full.acf && capability.full.acf.length > 0 &&
                <>
                    <h3 className="mt-10 font-semibold text-xl text-gray-800">Featured Resources</h3>

                    <ul className={'list-disc pl-4'}>
                        {capability.full.acf.map((video, videoIndex) => {
                            if(videoIndex >= 3)
                                return null;

                           return (
                               <li key={videoIndex} className={'mt-3 '}>
                                   <a className="text-green-500" target={'_blank'}
                                      href={video.url}>{video.title}</a>
                               </li>
                           )
                        })}

                    </ul>

                    <a target={'_blank'} className={'text-green-500 block mt-3'} href={"https://finops.org/resources/?prod_combined-resources[refinementList][related_capabilities.title][0]=" + encodeURIComponent(capability.title)}>View related resources</a>
                </>
            }
        </>
    )
}

export function ShareSummary(){
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const capabilities = useSelector(state => state.user.capabilities);
    const user = useSelector(state => state.user);
    const assessment = useSelector(state => state.assessment);

    if(assessment.isLoading){
        return <Loading />;
    }

    const lenses = [
        'knowledge',
        'process',
        'metrics',
        'adoption',
        'automation'
    ];

    let capabilitiesList = [];
    let leadCapability = {};
    let lowestCapability = {};
    assessment.capabilities.forEach((cap) => {
        capabilitiesList[capabilitiesList.length] = _.unescape(cap.title)

        if(cap.total_score > leadCapability.total_score || !leadCapability.total_score){
            leadCapability = cap;
        }

        if((cap.total_target_score - cap.score) < (lowestCapability.total_target_score - lowestCapability.total_score) || !lowestCapability.total_score){
            lowestCapability = cap;
        }
    });
    capabilitiesList = capabilitiesList.join(', ');
    let sorted_caps = [...assessment.capabilities];
    sorted_caps = sorted_caps.sort((a, b) => {
        return (a.total_score - a.total_target_score) - (b.total_score - b.total_target_score) || a.title.localeCompare(b.title);
    });

    const group_name = assessment.group_name ? assessment.group_name : user.group;

    return (
        <>
            <Header />
            <section className={'py-20'}>
                <div className={'container mx-auto'}>
                    <div className="flex justify-between items-center mb-10">
                        <h1 className={'text-3xl'}>FinOps Assessment Summary for {group_name}</h1>

                        <div className={'flex items-center'}>
                            <button id={'copyContainer'} onClick={copyToClipboard} className="inline-flex w-[200px] items-center justify-center py-2 px-2 sm:px-4 text-sm leading-relaxed font-medium rounded-sm text-green-600 border border-green-500 font-normal hover:border-green-600 transition-colors duration-200 print:hidden mr-2">
                                <svg className={'mr-[10px]'} width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                                    <path fill={'#1a866b'} d="m346.7 714.6 368.1-368.1c17.9-17.9 17.9-47.1 0-64.9-17.9-17.9-47.1-17.9-64.9 0l-368 368c-17.9 17.9-17.9 47.1 0 64.9 17.7 18 47 18 64.8.1zM468.2 651c6.4 9 7.6 18.5 7.6 23.8 0 6.3-1.5 18.6-11.8 28.9L271 896.5c-10.3 10.3-22.5 11.8-28.9 11.8-6.3 0-18.6-1.5-28.9-11.8L103.5 786.8c-10.3-10.3-11.8-22.5-11.8-28.9 0-6.4 1.5-18.6 11.8-28.9l192.9-192.9c10.3-10.3 22.5-11.8 28.9-11.8 4.5 0 11.8.8 19.3 4.8l58.6-58.6c-48-39.6-119.6-36.9-164.5 7.9l-193 192.9C-2 719-2 796.8 45.7 844.5l109.7 109.7c47.7 47.7 125.5 47.7 173.2 0l192.9-192.9c46-46 47.6-120.4 4.6-168.4L468.2 651zm486-495.5L844.5 45.8C796.9-1.9 719-1.9 671.3 45.8L478.4 238.7c-44.8 44.8-47.5 116.5-7.9 164.5l58.6-58.6c-4-7.6-4.8-14.9-4.8-19.3 0-6.3 1.5-18.6 11.8-28.9L729 103.5c10.3-10.3 22.5-11.8 28.9-11.8 6.3 0 18.6 1.5 28.9 11.8l109.7 109.7c10.3 10.3 11.8 22.5 11.8 28.9 0 6.3-1.5 18.6-11.8 28.9L703.7 464c-10.3 10.3-22.5 11.8-28.9 11.8-5.3 0-14.8-1.1-23.8-7.6l-58 58c48 43 122.3 41.5 168.4-4.6l192.9-192.9c47.6-47.6 47.6-125.6-.1-173.2z"/>
                                </svg>

                                <span>Copy Link to Clipboard</span>
                            </button>
                            <button onClick={generatePDF} className="inline-flex justify-center py-2 px-2 sm:px-4 text-sm leading-relaxed font-medium rounded-sm text-white border border-green-500 font-normal bg-green-500 hover:bg-green-600 hover:border-green-600 transition-colors duration-200 print:hidden mr-2">Export</button>
                        </div>
                    </div>

                    {/*<ul>*/}
                    {/*    <li>The Assessment focused on <strong>{assessment.capabilities.length}</strong> key {assessment.capabilities.length > 1 ? 'capabilites' : 'capability'}, {capabilitiesList}.</li>*/}
                    {/*    <li><strong>{assessment.group_name}</strong> is leading in the {leadCapability.title} capability with a <strong>{(100 * leadCapability.total_score) / 20}%</strong> scoring, indicating high maturity and implementation.</li>*/}
                    {/*    <li>You indicated <strong>{lowestCapability.title}</strong> the furthest from target in all 5 lenses (Knowledge, Process, Metric, Adoption, Automation)</li>*/}
                    {/*</ul>*/}

                    <div className={'bg-white p-10 md:mt-10 rounded-lg card'}>
                        <table className={'w-full table-fixed'}>
                            <thead>
                                <tr>
                                    <th className={'text-start'}>Capability</th>
                                    <th className={"small"}>Score</th>
                                    <th className={"small"}>Scope Target</th>
                                    <th className={"small"}>Relative to Target</th>
                                    <th className={"small"}>% Progress</th>
                                    <th className={"small"}>Comments</th>
                                </tr>
                            </thead>

                            <tbody>
                                {sorted_caps.map((capability, index) => {
                                    const {relativeBackground, relativeScore, relativeScorePercentage} = getRelativeVars(capability);

                                    return (
                                        <tr key={index}>
                                            <th>
                                                <a href={'#capability-' + index} className={'font-normal text-green-600'} dangerouslySetInnerHTML={{__html: capability.title}} />
                                            </th>
                                            <td>
                                                <input className={'cursor-default bg-gray-200'} value={capability.total_score} type="text" readOnly={true} />
                                            </td>
                                            <td>
                                                <input className={'cursor-default bg-gray-200'} value={capability.total_target_score} type="text" readOnly={true} />
                                            </td>
                                            <td>
                                                <input className={'cursor-default ' + relativeBackground}  type={'text'} readOnly={true} value={relativeScore} />
                                            </td>
                                            <td>
                                                <input className={'cursor-default ' + relativeBackground}  type={'text'} readOnly={true} value={relativeScorePercentage + '%'} />
                                            </td>
                                            <td>
                                                {(!!capability.knowledge_comment || !!capability.process_comment || !!capability.metrics_comment || !!capability.adoption_comment || !!capability.automation_comment) &&
                                                    <button className={'text-green-600'} onClick={() => dispatch(toggleCapabilityComment({index}))}>View</button>
                                                }

                                                {capability.show_comments &&
                                                    <>
                                                        <div onClick={() => dispatch(toggleCapabilityComment({index}))} className="bg-gray-800 opacity-50 h-[100vh] w-[100vw] fixed top-0 left-0 z-10"></div>

                                                        <div className="fixed pb-[100px] z-50 h-[470px] w-1/3 m-auto text-start inset-x-0 inset-y-0 p-5 bg-white rounded-lg overflow-y-scroll">
                                                            <h2 className={"text-2xl"}>{capability.title} Comments</h2>

                                                            {!!capability.knowledge_comment && capability.knowledge_comment.length > 1 &&
                                                                <div className={'mt-5'}>
                                                                    <h3>Knowledge</h3>
                                                                    <p>{capability.knowledge_comment}</p>
                                                                </div>
                                                            }

                                                            {!!capability.process_comment && capability.process_comment.length > 1 &&
                                                                <div className={'mt-5'}>
                                                                    <h3>Process</h3>
                                                                    <p>{capability.process_comment}</p>
                                                                </div>
                                                            }

                                                            {!!capability.metrics_comment && capability.metrics_comment.length > 1 &&
                                                                <div className={'mt-5'}>
                                                                    <h3>Metrics</h3>
                                                                    <p>{capability.metrics_comment}</p>
                                                                </div>
                                                            }

                                                            {!!capability.adoption_comment && capability.adoption_comment.length > 1 &&
                                                                <div className={'mt-5'}>
                                                                    <h3>Adoption</h3>
                                                                    <p>{capability.adoption_comment}</p>
                                                                </div>
                                                            }

                                                            {!!capability.automation_comment && capability.automation_comment.length > 1 &&
                                                                <div className={'mt-5'}>
                                                                    <h3>Automation</h3>
                                                                    <p>{capability.automation_comment}</p>
                                                                </div>
                                                            }

                                                            <div className="absolute bottom-8 right-8">
                                                                <button onClick={() => dispatch(toggleCapabilityComment({index}))} className="border border-gray-700 text-gray-700 rounded-lg px-10 py-1 hover:bg-gray-700 hover:text-white">Close</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {sorted_caps.map((capability, index) => {
                        const options = {
                            scales: {
                                r: {
                                    suggestedMin: 0,
                                    suggestedMax: 4,
                                    ticks: {
                                        stepSize: 1,
                                        backdropColor: 'rgba(0,0,0,0)',
                                    }
                                },
                            },
                        }
                        
                        const adjustedData = {
                            knowledge: {
                                score: capability.knowledge_score > capability.knowledge_target_score ? capability.knowledge_target_score : capability.knowledge_score,
                                target: capability.knowledge_target_score
                            },
                            process: {
                                score: capability.process_score > capability.process_target_score ? capability.process_target_score : capability.process_score,
                                target: capability.process_target_score
                            },
                            metrics: {
                                score: capability.metrics_score > capability.metrics_target_score ? capability.metrics_target_score : capability.metrics_score,
                                target: capability.metrics_target_score
                            },
                            adoption: {
                                score: capability.adoption_score > capability.adoption_target_score ? capability.adoption_target_score : capability.adoption_score,
                                target: capability.adoption_target_score
                            },
                            automation: {
                                score: capability.automation_score > capability.automation_target_score ? capability.automation_target_score : capability.automation_score,
                                target: capability.automation_target_score
                            }
                        }
                        
                        const data = {
                            redraw: true,
                            labels: ['Knowledge', 'Metrics', 'Process', 'Adoption', 'Automation'],
                            datasets: [
                                {
                                    label: 'Score',
                                    data: [adjustedData.knowledge.score, adjustedData.metrics.score, adjustedData.process.score, adjustedData.adoption.score, adjustedData.automation.score],
                                    backgroundColor: 'rgba(32,163,130,0.7)',
                                    borderColor: 'rgba(32,163,130,0.7)',
                                    opacity: 0.5,
                                    borderWidth: 1,
                                    tension: 0.2,
                                },
                                {
                                    label: 'Target',
                                    data: [adjustedData.knowledge.target, adjustedData.metrics.target, adjustedData.process.target, adjustedData.adoption.target, adjustedData.automation.target],
                                    backgroundColor: 'transparent',
                                    borderColor: 'rgba(250,187,6,0.7)',
                                    opacity: 0.5,
                                    borderWidth: 3,
                                    borderDash: [5, 5],
                                    tension: 0.2,
                                },
                            ],
                        };

                        return (
                            <div id={'capability-' + index} key={index} className="card mt-20 open-card card-full bg-white col-span-3 rounded-lg shadow">
                                <div className="grid grid-cols-12">
                                    <div
                                        className="graph bg-gray-200 rounded-l-lg col-span-12 md:col-span-7 flex items-center justify-center p-8">
                                        <div className="canvas-container">
                                            <Radar options={options} data={data} />
                                        </div>
                                    </div>
                                    <div className="p-8 pb-[9rem] relative col-span-12 md:col-span-5">
                                        <CapabilityCardContent capability={capability} />

                                        {(!!capability.knowledge_comment || !!capability.process_comment || !!capability.metrics_comment || !!capability.adoption_comment || !!capability.automation_comment) &&
                                            <div className="absolute bottom-8 right-8">
                                                <button onClick={() => dispatch(toggleCapabilityComment({index}))}
                                                        className="ml-4 border border-blue text-blue rounded-lg px-10 py-1 hover:bg-blue hover:text-white">Comments
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div style={{display: 'none'}}>
                    <div id={'exportPDF'} className={'container pt-[50px] mx-auto px-10'}>

                        <svg width={162} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.948 70.807">
                            <g id="Edit">
                                <path fill="#343d53"
                                      d="M83.441,29.907V1.7h16.812V4.689H86.836v9.456H99.081v2.991H86.836V29.907Z"/>
                                <path fill="#343d53"
                                      d="M105.991,4.162a1.968,1.968,0,0,1-1.515-.5,1.859,1.859,0,0,1-.465-1.313V1.819a1.866,1.866,0,0,1,.465-1.314,2.527,2.527,0,0,1,3.031,0,1.871,1.871,0,0,1,.465,1.314v.525a1.863,1.863,0,0,1-.465,1.313A1.972,1.972,0,0,1,105.991,4.162Zm-1.617,4.891h3.234V29.907h-3.234Z"/>
                                <path fill="#343d53"
                                      d="M111.326,29.907V9.053h3.233V12.41h.162a6.784,6.784,0,0,1,2.162-2.8,6.115,6.115,0,0,1,3.739-1.041,6.542,6.542,0,0,1,5.112,2.082,8.475,8.475,0,0,1,1.879,5.88V29.907H124.38V17.068q0-5.631-4.729-5.63a7.254,7.254,0,0,0-1.879.243,5.446,5.446,0,0,0-1.636.729,3.591,3.591,0,0,0-1.152,1.235,3.517,3.517,0,0,0-.425,1.762v14.5Z"/>
                                <path fill="#343d53"
                                      d="M143.335,30.393a12.41,12.41,0,0,1-4.952-.95,10.1,10.1,0,0,1-3.758-2.789,12.894,12.894,0,0,1-2.4-4.567,21.172,21.172,0,0,1-.849-6.284,20.976,20.976,0,0,1,.849-6.265,13.117,13.117,0,0,1,2.4-4.566,10,10,0,0,1,3.758-2.809,13.324,13.324,0,0,1,9.882,0,9.968,9.968,0,0,1,3.779,2.809,13.168,13.168,0,0,1,2.405,4.566A21.01,21.01,0,0,1,155.3,15.8a21.207,21.207,0,0,1-.848,6.284,12.944,12.944,0,0,1-2.405,4.567,10.065,10.065,0,0,1-3.779,2.789A12.447,12.447,0,0,1,143.335,30.393Zm0-2.992a8.545,8.545,0,0,0,3.354-.648,7.492,7.492,0,0,0,2.647-1.866,8.51,8.51,0,0,0,1.718-2.92,11.313,11.313,0,0,0,.606-3.812V13.45a11.313,11.313,0,0,0-.606-3.812,8.51,8.51,0,0,0-1.718-2.92,7.489,7.489,0,0,0-2.647-1.865,8.981,8.981,0,0,0-6.709,0,7.48,7.48,0,0,0-2.647,1.865,8.494,8.494,0,0,0-1.718,2.92,11.313,11.313,0,0,0-.606,3.812v4.705a11.313,11.313,0,0,0,.606,3.812,8.494,8.494,0,0,0,1.718,2.92,7.483,7.483,0,0,0,2.647,1.866A8.539,8.539,0,0,0,143.335,27.4Z"/>
                                <path fill="#343d53"
                                      d="M158.449,9.053h3.234V12.41h.161a5.861,5.861,0,0,1,2.365-2.9,7.1,7.1,0,0,1,3.738-.94,7.935,7.935,0,0,1,3.516.768,7.649,7.649,0,0,1,2.688,2.183,10.024,10.024,0,0,1,1.7,3.435,16.223,16.223,0,0,1,.586,4.526,16.226,16.226,0,0,1-.586,4.527,10.024,10.024,0,0,1-1.7,3.435,7.647,7.647,0,0,1-2.688,2.182,7.922,7.922,0,0,1-3.516.769,6.316,6.316,0,0,1-6.1-3.842h-.161V37.99h-3.234Zm8.609,18.47a5.564,5.564,0,0,0,4.324-1.726,6.455,6.455,0,0,0,1.576-4.529V17.693a6.454,6.454,0,0,0-1.576-4.529,5.564,5.564,0,0,0-4.324-1.726,7.01,7.01,0,0,0-2.082.3,5.775,5.775,0,0,0-1.717.832,4,4,0,0,0-1.152,1.279,3.214,3.214,0,0,0-.424,1.605v7.8a3.772,3.772,0,0,0,.424,1.809,4.071,4.071,0,0,0,1.152,1.34,5.209,5.209,0,0,0,1.717.832A7.49,7.49,0,0,0,167.058,27.523Z"/>
                                <path fill="#343d53"
                                      d="M187.006,30.393a9.748,9.748,0,0,1-4.87-1.112,11.071,11.071,0,0,1-3.375-2.971l2.3-1.859a9.05,9.05,0,0,0,2.707,2.364,7.05,7.05,0,0,0,3.476.829,5.147,5.147,0,0,0,3.213-.889,3.23,3.23,0,0,0,.364-4.587,4.809,4.809,0,0,0-2.85-1.192l-1.656-.243a13.859,13.859,0,0,1-2.668-.626,7.445,7.445,0,0,1-2.122-1.132,4.862,4.862,0,0,1-1.414-1.758,5.719,5.719,0,0,1-.5-2.506,6.024,6.024,0,0,1,.565-2.687,5.406,5.406,0,0,1,1.557-1.92A6.782,6.782,0,0,1,184.1,8.953a10.878,10.878,0,0,1,2.95-.385,9.2,9.2,0,0,1,4.264.89,9.575,9.575,0,0,1,3.011,2.464l-2.142,1.94a6.38,6.38,0,0,0-5.376-2.545,4.632,4.632,0,0,0-3.051.868,2.932,2.932,0,0,0-1.03,2.365,2.472,2.472,0,0,0,1.03,2.222,7.687,7.687,0,0,0,2.971,1.011l1.616.242a8.674,8.674,0,0,1,4.931,2,5.261,5.261,0,0,1,1.455,3.86,5.907,5.907,0,0,1-2.061,4.769A8.511,8.511,0,0,1,187.006,30.393Z"/>
                                <path fill="#343d53"
                                      d="M83.441,70.322V42.113h16.812V45.1H86.836V54.56H99.081v2.991H86.836V70.322Z"/>
                                <path fill="#343d53"
                                      d="M112.232,70.807a9.392,9.392,0,0,1-3.84-.768,8.288,8.288,0,0,1-2.97-2.2A10.375,10.375,0,0,1,103.5,64.4a15.055,15.055,0,0,1,0-8.992,10.33,10.33,0,0,1,1.92-3.456,8.279,8.279,0,0,1,2.97-2.2,9.409,9.409,0,0,1,3.84-.768,9.275,9.275,0,0,1,3.819.768,8.51,8.51,0,0,1,2.97,2.2,10.146,10.146,0,0,1,1.94,3.456,15.034,15.034,0,0,1,0,8.992,10.188,10.188,0,0,1-1.94,3.435,8.519,8.519,0,0,1-2.97,2.2A9.259,9.259,0,0,1,112.232,70.807Zm0-2.869a5.876,5.876,0,0,0,4.284-1.617,6.609,6.609,0,0,0,1.657-4.931V58.4a6.612,6.612,0,0,0-1.657-4.931,6.483,6.483,0,0,0-8.568,0,6.608,6.608,0,0,0-1.657,4.931v2.99a6.605,6.605,0,0,0,1.657,4.931A5.873,5.873,0,0,0,112.232,67.938Z"/>
                                <path fill="#343d53"
                                      d="M137.894,66.966h-.161a10.12,10.12,0,0,1-.789,1.421,5.3,5.3,0,0,1-1.152,1.24,5.416,5.416,0,0,1-1.657.86,7.208,7.208,0,0,1-2.262.32,6.6,6.6,0,0,1-5.133-2.081,8.411,8.411,0,0,1-1.9-5.881V49.468h3.233V62.307q0,5.631,4.769,5.631a7.28,7.28,0,0,0,1.879-.243,5.209,5.209,0,0,0,1.617-.729,3.685,3.685,0,0,0,1.132-1.236,3.6,3.6,0,0,0,.424-1.8V49.468h3.234V70.322h-3.234Z"/>
                                <path fill="#343d53"
                                      d="M145.654,70.322V49.468h3.234v3.357h.161a6.792,6.792,0,0,1,2.162-2.8,6.109,6.109,0,0,1,3.739-1.041,6.548,6.548,0,0,1,5.112,2.081,8.472,8.472,0,0,1,1.879,5.881V70.322h-3.233V57.483q0-5.629-4.728-5.631a7.256,7.256,0,0,0-1.88.244,5.421,5.421,0,0,0-1.636.729,3.6,3.6,0,0,0-1.152,1.235,3.515,3.515,0,0,0-.424,1.761v14.5Z"/>
                                <path fill="#343d53"
                                      d="M180.007,66.966h-.162a6.366,6.366,0,0,1-6.1,3.841,7.922,7.922,0,0,1-3.516-.768,7.626,7.626,0,0,1-2.687-2.183,9.972,9.972,0,0,1-1.7-3.435,17.771,17.771,0,0,1,0-9.052,9.99,9.99,0,0,1,1.7-3.435,7.637,7.637,0,0,1,2.687-2.183,7.935,7.935,0,0,1,3.516-.768,7.1,7.1,0,0,1,3.739.941,5.858,5.858,0,0,1,2.364,2.9h.162V40.415h3.233V70.322h-3.233Zm-5.375.972a7.53,7.53,0,0,0,2.081-.284,5.211,5.211,0,0,0,1.717-.833,4.062,4.062,0,0,0,1.152-1.341,3.753,3.753,0,0,0,.425-1.807v-7.8a3.2,3.2,0,0,0-.425-1.605,4.009,4.009,0,0,0-1.152-1.279,5.8,5.8,0,0,0-1.717-.832,7.014,7.014,0,0,0-2.081-.306,5.564,5.564,0,0,0-4.325,1.728,6.454,6.454,0,0,0-1.576,4.528v3.574a6.459,6.459,0,0,0,1.576,4.53A5.566,5.566,0,0,0,174.632,67.938Z"/>
                                <path fill="#343d53"
                                      d="M204.012,70.322a3.171,3.171,0,0,1-2.606-.97,4.808,4.808,0,0,1-.991-2.424h-.2a5.4,5.4,0,0,1-2.263,2.91,7.038,7.038,0,0,1-3.759.969,7.316,7.316,0,0,1-5.153-1.7A5.965,5.965,0,0,1,187.2,64.5a5.263,5.263,0,0,1,2.162-4.527q2.163-1.577,6.73-1.576h4.122V56.338A4.508,4.508,0,0,0,199,52.944a5.177,5.177,0,0,0-3.719-1.172,5.6,5.6,0,0,0-3.172.849,6.99,6.99,0,0,0-2.122,2.262l-1.94-1.818a7.384,7.384,0,0,1,2.708-2.89,8.526,8.526,0,0,1,4.688-1.192,8.618,8.618,0,0,1,5.9,1.855,6.526,6.526,0,0,1,2.1,5.162V67.452h2.385v2.87Zm-9.295-2.263a8.244,8.244,0,0,0,2.222-.283,5.418,5.418,0,0,0,1.738-.809,3.987,3.987,0,0,0,1.132-1.212,2.9,2.9,0,0,0,.4-1.495V60.825h-4.284a7.824,7.824,0,0,0-4.061.807,2.611,2.611,0,0,0-1.274,2.345v.848a2.823,2.823,0,0,0,1.112,2.385A4.84,4.84,0,0,0,194.717,68.059Z"/>
                                <path fill="#343d53"
                                      d="M214.2,70.322a3.313,3.313,0,0,1-2.567-.947,3.528,3.528,0,0,1-.869-2.48V52.338h-3.435v-2.87h1.94a1.906,1.906,0,0,0,1.435-.424,2.2,2.2,0,0,0,.384-1.475v-3.88h2.909v5.779h4.567v2.87h-4.567V67.452h4.244v2.87Z"/>
                                <path fill="#343d53"
                                      d="M225.256,44.578a1.972,1.972,0,0,1-1.515-.505,1.865,1.865,0,0,1-.465-1.314v-.525a1.866,1.866,0,0,1,.465-1.314,2.527,2.527,0,0,1,3.031,0,1.871,1.871,0,0,1,.465,1.314v.525a1.869,1.869,0,0,1-.465,1.314A1.976,1.976,0,0,1,225.256,44.578Zm-1.616,4.89h3.233V70.322H223.64Z"/>
                                <path fill="#343d53"
                                      d="M239.7,70.807a9.391,9.391,0,0,1-3.839-.768,8.271,8.271,0,0,1-2.97-2.2,10.357,10.357,0,0,1-1.92-3.435,15.055,15.055,0,0,1,0-8.992,10.313,10.313,0,0,1,1.92-3.456,8.262,8.262,0,0,1,2.97-2.2,9.407,9.407,0,0,1,3.839-.768,9.269,9.269,0,0,1,3.819.768,8.5,8.5,0,0,1,2.971,2.2,10.162,10.162,0,0,1,1.94,3.456,15.055,15.055,0,0,1,0,8.992,10.205,10.205,0,0,1-1.94,3.435,8.514,8.514,0,0,1-2.971,2.2A9.253,9.253,0,0,1,239.7,70.807Zm0-2.869a5.876,5.876,0,0,0,4.284-1.617,6.605,6.605,0,0,0,1.657-4.931V58.4a6.608,6.608,0,0,0-1.657-4.931,6.482,6.482,0,0,0-8.567,0,6.608,6.608,0,0,0-1.657,4.931v2.99a6.605,6.605,0,0,0,1.657,4.931A5.871,5.871,0,0,0,239.7,67.938Z"/>
                                <path fill="#343d53"
                                      d="M251.66,70.322V49.468h3.234v3.357h.161a6.8,6.8,0,0,1,2.162-2.8,6.109,6.109,0,0,1,3.739-1.041,6.548,6.548,0,0,1,5.112,2.081,8.472,8.472,0,0,1,1.88,5.881V70.322h-3.233V57.483q0-5.629-4.729-5.631a7.256,7.256,0,0,0-1.88.244,5.432,5.432,0,0,0-1.636.729,3.6,3.6,0,0,0-1.152,1.235,3.515,3.515,0,0,0-.424,1.761v14.5Z"/>
                                <polygon fill="#343d53"
                                         points="26.503 70.322 0 55.507 0 25.046 26.503 39.86 26.503 70.322"/>
                                <polygon fill="#343d53"
                                         points="35.565 70.322 62.068 55.507 62.068 25.046 35.565 39.86 35.565 70.322"/>
                                <polygon fill="#343d53"
                                         points="31.03 1.698 4.547 17.419 31.03 32.222 57.513 17.419 31.03 1.698"/>
                            </g>
                        </svg>

                        <h1 className={'text-3xl mb-5 mt-16'}>FinOps Assessment Summary</h1>

                        <ul>
                            <li>The Assessment focused on <strong>{assessment.capabilities.length}</strong> key {assessment.capabilities.length > 1 ? 'capabilites' : 'capability'}, {capabilitiesList}.</li>
                            <li><strong>{assessment.group_name}</strong> is leading in the {leadCapability.title} capability with a <strong>{(100 * leadCapability.total_score) / 20}%</strong> scoring, indicating high maturity and implementation.</li>
                            <li>You indicated <strong>{lowestCapability.title}</strong> the furthest from target in all 5 lenses (Knowledge, Process, Metric, Adoption, Automation)</li>
                        </ul>

                        <div className={'bg-white md:mt-10 '}>
                            <table className={'w-full table-fixed'}>
                                <thead>
                                <tr>
                                    <th className={'text-start'}>Capability</th>
                                    <th className={"small"}>Total Score</th>
                                    <th className={"small"}>Scope Target</th>
                                    <th className={"small"}>Relative to Target</th>
                                    <th className={"small"}>% Progress</th>
                                </tr>
                                </thead>

                                <tbody>
                                {sorted_caps.map((capability, index) => {
                                    let relativeScore = capability.total_score - capability.total_target_score;
                                    let relativeScorePercentage = Math.round((100 * capability.total_score / capability.total_target_score));

                                    if(isNaN(relativeScorePercentage)){
                                        relativeScorePercentage = 0;
                                    }

                                    if(!isFinite(relativeScorePercentage)){
                                        relativeScorePercentage = '>' + 100;
                                    }

                                    let relativeBackground = 'bg-white';
                                    if(relativeScore > 0) {
                                        relativeScore = '+' + relativeScore;
                                        relativeBackground = 'bg-green-500'
                                    }
                                    else if(relativeScore < 0){
                                        relativeBackground = 'bg-red-400';
                                    }

                                    return (
                                        <tr key={index}>
                                            <th>
                                                <span className={'font-normal'} dangerouslySetInnerHTML={{__html: capability.title}} />
                                            </th>
                                            <td>
                                                <input className={'cursor-default bg-gray-200'} value={capability.total_score} type="text" readOnly={true} />
                                            </td>
                                            <td>
                                                <input className={'cursor-default bg-gray-200'} value={capability.total_target_score} type="text" readOnly={true} />
                                            </td>
                                            <td>
                                                <input className={'cursor-default ' + relativeBackground}  type={'text'} readOnly={true} value={relativeScore} />
                                            </td>
                                            <td>
                                                <input className={'cursor-default ' + relativeBackground}  type={'text'} readOnly={true} value={relativeScorePercentage + '%'} />
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>

                        <h2 className={"text-2xl mt-10 mb-5"}>Comments</h2>

                        {sorted_caps.map((capability, index) => {
                            return (
                                (!!capability.knowledge_comment || !!capability.process_comment || !!capability.metrics_comment || !!capability.adoption_comment || !!capability.automation_comment) &&
                                <div key={index}>
                                    <h3 className={'text-lg mb-2'}>{capability.title}</h3>

                                    {!!capability.knowledge_comment && capability.knowledge_comment.length > 1 &&
                                    <div className={'mb-5'}>
                                        <h3>Knowledge</h3>
                                        <p className={'mb-0'}>{capability.knowledge_comment}</p>
                                    </div>
                                    }

                                    {!!capability.process_comment && capability.process_comment.length > 1 &&
                                    <div className={'mb-5'}>
                                        <h3>Process</h3>
                                        <p className={'mb-0'}>{capability.process_comment}</p>
                                    </div>
                                    }

                                    {!!capability.metrics_comment && capability.metrics_comment.length > 1 &&
                                    <div className={'mb-5'}>
                                        <h3>Metrics</h3>
                                        <p className={'mb-0'}>{capability.metrics_comment}</p>
                                    </div>
                                    }

                                    {!!capability.adoption_comment && capability.adoption_comment.length > 1 &&
                                    <div className={'mb-5'}>
                                        <h3>Adoption</h3>
                                        <p className={'mb-0'}>{capability.adoption_comment}</p>
                                    </div>
                                    }

                                    {!!capability.automation_comment && capability.automation_comment.length > 1 &&
                                    <div className={'mb-5'}>
                                        <h3>Automation</h3>
                                        <p className={'mb-0'}>{capability.automation_comment}</p>
                                    </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}