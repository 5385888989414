import React, { useState, useEffect }  from 'react';
import {setGroup, setLensTargetScore, toggleCapability} from '../reducers/user';
import {openAccordionItem} from '../reducers/lens';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, select, ErrorMessage } from 'formik';
import { useNavigate, Navigate } from "react-router-dom";
import { Formik, Form, select, ErrorMessage } from 'formik';
import {Button} from "../components/Button";
import { Header } from '../components/Header'
import {LensAccordion} from "../components/LensAccordion";
import {ProgressIndicator} from "../components/ProgressIndicator";

export function TargetScore(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const capabilities = useSelector(state => state.user.capabilities);
    const lenses = useSelector(state => state.lenses);
    const user = useSelector(state => state.user);

    if(!user.group.length || !Object.keys(user.capabilities).length){
        return <Navigate to={'/assessment'} />
    }

    const beforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
    }

    useEffect(() => {
        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, []);

    return (
        <>
            <Header />
            <section className={'pt-20 pb-[4px]'}>
                <div className={'container pb-20 mx-auto'}>
                    <h1 className={'text-3xl mb-5'}>Step 3: Set Your Capability Target Scores</h1>

                    <div className="grid grid-cols-12 gap-9">
                        <div className="col-span-8">
                            <p>We use the concept of a Lens to talk about how you inspect and analyze a FinOps Practice. These lenses are essentially different functional inspection views through which to consider any given Framework Capability, each requiring different effort, and evidence to support.</p>

                            <ul className={'list-disc pl-5 mb-8'}>
                                <li className={'mb-3'}>The <strong className={"font-semi-bold"}>KNOWLEDGE</strong> lens considers the capability's breadth of understanding and awareness across the target group. How widely is this concept, its mechanisms, terms, and processes known?</li>
                                <li className={'mb-3'}>The <strong className={"font-semi-bold"}>PROCESS</strong> lens relates to the set of actions being performed in order to deliver the capability being assessed. How well are these actions defined and documented? Consider the efficacy, validity and prevalence of such processes.</li>
                                <li className={'mb-3'}>The <strong className={"font-semi-bold"}>METRICS</strong> lens indicates how measurement are defined & used for each capability. Is there a way to measure and prove progress over time? How are those measurements obtained, and how relevant are they? Note that this lens does not evaluate how high any specific metric is to benchmark the goal.</li>
                                <li className={'mb-3'}>The <strong className={"font-semi-bold"}>ADOPTED</strong> lens looks at how widely has the target group accepted this capability as part of its essential operations. Is this capability part of the target group's BAU (Business As Usual) behaviors?</li>
                                <li className={'mb-3'}>The <strong className={"font-semi-bold"}>AUTOMATION</strong> lens examines capabilities and offloading tasks to automation solutions to drive consistency, speed, and scalability across your target group.</li>
                            </ul>

                            <p>For each capability you will be asked to set a <strong>TARGET SCORE</strong> for each lens. The target is the score you want to achieve in the future.</p>

                            <p><strong>This is the score you are aiming for, not the score you currently have. </strong></p>
                        </div>

                        <div className="col-span-4">
                            <div className='embed-container'>
                                <iframe src='https://www.youtube.com/embed/kXiS-Ko9IYs' frameBorder='0' allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>

                    <hr className={'mt-8 mb-10'} />

                    <h3 className={'text-xl mb-5'}>Target Scores for {user.group}</h3>

                    <div className="grid grid-cols-12 gap-9">
                        <div className="col-span-9">
                            <div className="bg-white rounded-lg shadow-xl p-10">
                                <table className={'w-full table-fixed'}>
                                    <thead>
                                        <tr>
                                            <th />
                                            <th className={"small"}>Knowledge <span onClick={() => dispatch(openAccordionItem('knowledge'))}  className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Process <span onClick={() => dispatch(openAccordionItem('process'))}  className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Metrics <span onClick={() => dispatch(openAccordionItem('metrics'))}  className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Adoption <span onClick={() => dispatch(openAccordionItem('adoption'))}  className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small"}>Automation <span onClick={() => dispatch(openAccordionItem('automation'))}  className={'helper bg-green-800 cursor-pointer'}>?</span></th>
                                            <th className={"small xs"}>Total</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {capabilities.map((capability, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th>
                                                        <strong className={'font-semibold'} dangerouslySetInnerHTML={{__html: capability.title}} />
                                                    </th>
                                                    {Object.keys(capability.lenses).map((lensKey, lensIndex) => {
                                                        const lens = capability.lenses[lensKey];
                                                        return (
                                                            <td key={lensIndex} className={'relative'}>
                                                                <select tabIndex={index + lensIndex} value={capability.lenses[lens.slug].targetScore} onChange={(value) => {
                                                                    dispatch(setLensTargetScore({
                                                                        index,
                                                                        lens: lens.slug,
                                                                        value: value.target.value
                                                                    }));
                                                                }} className={"p-2 bg-gray-100"}>
                                                                    {lenses[lens.slug].scores.map((lens, index) => {
                                                                        return <option key={index} value={index}>{index} / {lens.heading}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        )
                                                    })}
                                                    <td>
                                                        <input className={'cursor-default border-0 block bg-gray-200'} value={capability.totalTargetScore} type="text" readOnly={true} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-span-3">
                            <div className="bg-white rounded-lg shadow-xl p-10 py-7">
                                <LensAccordion />
                            </div>
                        </div>
                    </div>
                </div>

                <ProgressIndicator className={'progress-2'} step={'Step 2 of 4 - Target Scores'}>
                    <Button className={'bg-gray-700 border-gray-700'} onClick={() => {window.scrollTo(0, 0); navigate('/capabilities')}}>Prev</Button>
                    <Button className={'bg-green-600 border-green-500 ml-5'} onClick={() => {window.scrollTo(0, 0); navigate('/measurement')}}>Next</Button>
                </ProgressIndicator>
            </section>
        </>
    );
}