import React, { useState, useEffect }  from 'react';
import {forgotPassword, resetPassword} from '../reducers/user';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { useNavigate, Navigate } from "react-router-dom";
import { Button } from '../components/Button';
import { Header } from '../components/Header';
import { useParams } from "react-router-dom";
import {Loading} from "../components/Loading";

const HiddenFields = () => {
    const { setFieldValue } = useFormikContext();
    const [ params, setParams ] = useState({
        expires: '',
        hash: '',
        signature: '',
        user: ''
    });

    const queryParams = new URLSearchParams(window.location.search)

    useEffect(() => {
        let expires = queryParams.get('expires')
        let hash = queryParams.get('hash')
        let signature = queryParams.get('signature')
        let user = queryParams.get('user')

        setFieldValue('expires', expires);
        setFieldValue('hash', hash);
        setFieldValue('signature', signature);
        setFieldValue('user', user);

    }, [queryParams.get('expires')]);

    return (
        <>
            <Field type="hidden" name="expires" />
            <Field type="hidden" name="hash" />
            <Field type="hidden" name="signature" />
            <Field type="hidden" name="user" />
        </>
    )
}

export function ForgotPassword(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userReducer = useSelector(state => state.user);
    const [ showVerificationField, updateShowVerificationField ] = useState(false);
    const [ params, setParams ] = useState({
        expires: '',
        hash: '',
        signature: '',
        user: ''
    });
    const queryParams = new URLSearchParams(window.location.search)
    const [ verificationError, setVerificationError ] = useState(null);

    useEffect(() => {
        console.log(verificationError)

        let expires = queryParams.get('expires')
        let hash = queryParams.get('hash')
        let signature = queryParams.get('signature')
        let user = queryParams.get('user')

        if(expires && hash && signature && user)
            updateShowVerificationField(true);
    }, []);

        return (
        <>
            <Header />

            {userReducer.isLoading && <Loading />}

            {!userReducer.isLoading &&
                <section className={'py-20'}>
                    <div className={'container mx-auto'}>

                        <div className="grid px-20 grid-cols-12 gap-9">
                            <div className="col-span-12">
                                <h1 className="text-3xl text-center mb-10">Forgotten your password?</h1>
                            </div>

                            {userReducer.showVerificationSent &&
                                <div className="col-start-4 text-center col-span-6 p-10 bg-white rounded">
                                    <p>We've sent a verification link to your email address.</p>
                                </div>
                            }

                            {!userReducer.showVerificationSent &&
                                <div className="col-start-4 col-span-6 p-10 bg-white rounded">
                                    {!showVerificationField &&
                                    <Formik
                                        initialValues={{
                                            'password': '',
                                            'email': ''
                                        }}
                                        validate={values => {
                                            const errors = {};
                                            if (!values.email) {
                                                errors.email = 'Required';
                                            }

                                            return errors;
                                        }}
                                        onSubmit={(values, {setSubmitting}) => {
                                            window.scrollTo(0, 0);
                                            setSubmitting(true);
                                            dispatch(forgotPassword(values)).then(() => setSubmitting(false));
                                        }}
                                    >
                                        {({isSubmitting}) => (
                                            <Form className={'mt-5 '}>
                                                <div>
                                                    <p className={'mb-10'}>Don't worry! Just tell us your email address
                                                        below
                                                        and if you have an account we'll send you an email with a
                                                        verification
                                                        code so you can reset it.</p>

                                                    <label className={"mb-2 block"}><strong>Email</strong></label>
                                                    <Field placeholder={'johndoe@example.org'} type="text"
                                                           className={'border rounded-sm border-gray-600'} name="email"/>
                                                    <ErrorMessage name="email" component="div"/>
                                                </div>

                                                <div className={'mt-10'}>
                                                    <Button className={'bg-green-500 border-green-500'} type="submit"
                                                            disabled={isSubmitting}>
                                                        Send Verification Code
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    }

                                    {showVerificationField &&
                                    <Formik
                                        initialValues={{
                                            'expires': params.expires,
                                            'hash': params.hash,
                                            'user': params.user,
                                            'signature': params.signature,
                                        }}
                                        validate={values => {
                                            const errors = {};
                                            if (!values.password) {
                                                errors.password = 'Required';
                                            }

                                            return errors;
                                        }}
                                        onSubmit={(values, {setSubmitting}) => {
                                            window.scrollTo(0, 0);
                                            setSubmitting(true);

                                            dispatch(resetPassword(values)).then((response) => {
                                                if(response.type === 'user/resetPassword/rejected')
                                                    setVerificationError(response.payload.message);

                                                setSubmitting(false)
                                            });
                                        }}
                                    >
                                        {({isSubmitting}) => (
                                            <Form className={'mt-5 '}>
                                                <div className={'mt-5'}>
                                                    <p className={'mb-10'}>Thanks for verifiying your email address. Now
                                                        enter a
                                                        new password below and you'll be logged back in.</p>

                                                    {verificationError && <p className={'text-red-500'}>{verificationError}</p>}

                                                    <label className={"mb-2 mt-5 block"}><strong>New
                                                        Password</strong></label>
                                                    <Field type="password" className={'border rounded-sm border-gray-600'}
                                                           name="password"/>
                                                    <ErrorMessage name="password" component="div"/>

                                                    <HiddenFields/>
                                                </div>

                                                <div className={'mt-10'}>
                                                    <Button className={'bg-green-500 border-green-500'} type="submit"
                                                            disabled={isSubmitting}>
                                                        {showVerificationField ? 'Reset Password' : 'Send Verification Code'}
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    );
}