import React, { useState, useEffect }  from 'react';

export const Button = ({ children, onClick, className, ...props }) => {
    return (
        <button
            className={`inline-flex btn justify-center px-10 py-1 text-xl leading-relaxed rounded-sm text-white border font-normal hover:bg-green-600 hover:border-green-600 transition-colors duration-200 ${className}`}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
}