import React, { useState, useEffect }  from 'react';
import { toggleCapability } from '../reducers/user';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, Navigate } from "react-router-dom";
import { Button } from '../components/Button';

import {SearchBox} from "react-instantsearch-dom";

import { connectHits } from 'react-instantsearch-dom';
import {Header} from "../components/Header";
import {ProgressIndicator} from "../components/ProgressIndicator";

const Hits = ({ hits, dispatch, capabilities }) => (
    <div className={'grid relative grid-cols-1 lg:grid-cols-3 gap-9'}>
        {hits.map(hit => {
            if(hit.parent_id){
                return null;
            }

            return (
            <div onClick={() => dispatch(toggleCapability(hit))} className={'p-10 pt-14 transition ease-in relative cursor-pointer rounded-lg  border ' + (capabilities.find(capability => capability.objectID === hit.objectID) ? 'bg-gray-200 ' : 'card drop-shadow-xl bg-white hover:drop-shadow-lg')} key={hit.objectID}>
                <h3 className={'mb-3 text-xl'} dangerouslySetInnerHTML={{__html: hit.title}} />
                <div dangerouslySetInnerHTML={{__html: hit.excerpt}}></div>

                <span className={'absolute top-6 right-8 w-[20px] h-[20px] rounded-sm border border-black block' + (capabilities.find(capability => capability.objectID === hit.objectID) ? ' bg-green-600 border-green-600 checked' : '')} />

                <a onClick={e => e.stopPropagation()} target={'_blank'} className={'mt-5 text-green-600 inline-block hover:text-gray-700'} href={hit.url}>Read More</a>
            </div>
        )})}
    </div>
);

const CapabilityCount = ({ capabilities }) => {
    const [showCaps, setShowCaps] = useState(false);

    if(capabilities.length > 0){
        return (
            <div className="sticky bottom-[69px]">
                <div className="container flex justify-end mx-auto">
                    <div onClick={() => setShowCaps(!showCaps) } className=" text-white w-[400px] z-20 bg-gray-600 px-5 py-3 shadow-lg rounded-t-lg">
                        <h3 className={'flex justify-between cursor-pointer'}><span>You've selected {capabilities.length} {capabilities.length > 1 ? 'capabilities' : 'capability'}</span> <span className={"chevron " + (showCaps ? 'bottom' : 'top')} /></h3>
 
                        {showCaps &&
                            <ul>
                                {capabilities.map(capability => {
                                    return (
                                        <li className={'mt-3'} dangerouslySetInnerHTML={{__html: capability.title}} />
                                    )
                                })}
                            </ul>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return null;
}

const CustomHits = connectHits(Hits);

export function Capabilities(){
    const dispatch = useDispatch();
    const caps = useSelector((state) => state.user.capabilities);
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    if(!user.group.length){
        return <Navigate to={'/assessment'} />
    }

    const beforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
    }

    useEffect(() => {
        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, []);

    return (
        <>
            <Header />
            <section className={'pt-20 pb-[4px]'}>
                <div className={'container mx-auto'}>
                    <h1 className={'text-3xl mb-5'}>Step 2 : Select FinOps Framework capabilities</h1>
                    <p>Identify the FinOps capability(ies) you are going to be assessing the selected group on.</p>
                </div>

                <div className="bg-gray-800 pb-7 mb-[4rem] mt-20 py-5">
                    <div className="container mx-auto">
                        <div className="grid grid-cols-4 gap-9">
                            <div className={'col-span-2 col-start-2 '}>
                                <h4 className="text-white mb-2">Search Capabilities</h4>

                                <SearchBox placeholder={"Search for a capability"} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pb-20 mx-auto">
                    <CustomHits capabilities={caps} dispatch={dispatch} />
                </div>

                <CapabilityCount capabilities={user.capabilities} />

                <ProgressIndicator step={'Step 1 of 4 - Capabilities'}>
                    <Button className={'bg-gray-700 border-gray-700'} onClick={() => {window.scrollTo(0, 0); navigate('/')}}>Prev</Button>

                    <Button disabled={(user.capabilities.length === 0)} className={'bg-green-600 ml-5 border-green-600'} onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/target-score')
                    }}>Next</Button>
                </ProgressIndicator>
            </section>
        </>
    );
}