import React, { useState, useEffect }  from 'react';
import {getAssessments, setGroup, getAdminAssessments} from '../reducers/user';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {useNavigate, Navigate, Link} from "react-router-dom";
import { Button } from '../components/Button';
import { Header } from '../components/Header'

export function Admin(){
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    if(!user.isLoggedIn){
        return <Navigate to={'/login'} />
    }

    if(!user.super_user){
        return <Navigate to={'/'} />
    }

    useEffect(() => {
        dispatch(getAdminAssessments())
    }, [dispatch])

    return (
        <>
            <Header />
            <section className={'py-20'}>
                <div className={'container mx-auto'}>
                    <h1 className={'text-3xl mb-5'}>Admin Panel</h1>

                    {!!user.adminAssessments &&
                        <table className={'mt-4 table-fixed w-full'}>
                            <thead>
                            <tr>
                                <th className={'py-4 text-start border-b'}>User</th>
                                <th className={'py-4 text-start border-b'}>Company</th>
                                <th className={'py-4 text-start border-b'}>Group Assessed</th>
                                <th className={'py-4 text-start border-b'}>Created</th>
                                <th className={'py-4 w-1/12 border-b'} />
                            </tr>
                            </thead>

                            <tbody>
                            {user.adminAssessments.map((u, index) => {
                                return u.assessments.map((assessment, assessmentIndex) => {
                                    return (
                                        <tr key={index}>
                                            <td className={'py-4 text-start'}>{assessmentIndex === 0 ? <strong>{u.first_name} {u.last_name} ({u.email})</strong> : null}</td>
                                            <td className={'py-4 text-start'}>{assessmentIndex === 0 ? <strong>{u.company}</strong> : null}</td>
                                            <td className={'py-4 text-start'}><strong>{assessment.group_name}</strong></td>
                                            <td className={'py-4 text-start'}>{assessment.created_at}</td>
                                            <td className={'py-4 text-end'}><a target={'_blank'} href={'/summary/' + assessment.uuid} className={'text-green-600'}>View</a></td>
                                        </tr>
                                    )
                                })

                            })}
                            </tbody>
                        </table>
                    }
                </div>
            </section>
        </>
    );
}