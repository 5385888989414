import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { redirect } from "react-router-dom";

let API_URL = 'http://localhost/api';
if(process.env.NODE_ENV === 'prod') {
    API_URL = 'https://asessment-api.finops.org/api';
}

let initialState = {
    knowledge: {
        title: 'Knowledge',
        isAccordionOpen: false,
        scores: [
            {
                maturity: "0",
                heading: "No Knowledge",
                description: "At this level, knowledge of the capability as defined by the framework is effectively non-existent within the target group. There are no discussions happening about this capability, and nobody is actively involved in developing any further knowledge."
            },
            {
                maturity: "1",
                heading: "Partial Knowledge",
                description: "Preliminary knowledge of the Capability being assessed is now beginning to spread amongst key stakeholders and individuals, but the capability is not yet common knowledge across the target group."
            },
            {
                maturity: "2",
                heading: "Developing Knowledge",
                description: "Basic knowledge of the Capability is understood by a limited number of key stakeholders but information is limited in unscaled disbursements."
            },
            {
                maturity: "3",
                heading: "Full Knowledge",
                description: "Strong knowledge of the assessed capability is known throughout the target group, incorporated with starter onboarding and periodic reinforcement via training or other appropriate communication methods."
            },
            {
                maturity: "4",
                heading: "Knowledge Leader",
                description: "The target group is now driving awareness of this capability beyond itself to other peer groups and the wider industry. Leveraging deep awareness across individuals in the group, working to improve awareness and build new insights into the capability."
            }
        ]
    },
    process: {
        title: 'Process',
        isAccordionOpen: false,
        scores: [
            {
                maturity: "0",
                heading: "No Process",
                description: "There are no described, consistent processes in place governing any aspect of the assessed capability within the target group."
            },
            {
                maturity: "1",
                heading: "Implementing Process",
                description: "Processes to standardise this assessed capability are being formed, defined and possibly tested. Processes are not yet formalised as BAU for the target group."
            },
            {
                maturity: "2",
                heading: "Developing POC Process",
                description: "Relevant, functional processes are now in place, agreed upon and being followed by at least some of the teams."
            },
            {
                maturity: "3",
                heading: "Scaled Process",
                description: "Scaled processes are now in place, documented and you’ve implemented periodic feedback loops."
            },
            {
                maturity: "4",
                heading: "Mature (Agile) Process",
                description: "Clearly defined, transparent, and fully integrated processes are now universally established and consistent. This does not necessarily mean there is one process for each task but that processes in place are all in this state. Processes are being iterated on with key stakeholder feedback and ongoing agile refinement."
            }
        ]
    },
    metrics: {
        title: 'Metrics',
        isAccordionOpen: false,
        scores: [
            {
                maturity: "0",
                heading: "No Metrics",
                description: "There are no measurements being taken at this point. No direct insight is available concerning the progress or current state of this capability."
            },
            {
                maturity: "1",
                heading: "Identified Metrics",
                description: "Identified key metrics the business finds valuable for this capability to make trade-off decisions."
            },
            {
                maturity: "2",
                heading: "Baselined Metrics",
                description: "Initial, manually generated metrics are in place providing rudimentary traffic-light (eg: Red/Yellow/Green) measurement of this capability."
            },
            {
                maturity: "3",
                heading: "Established & collecting Metric Targets data",
                description: "KPIs are now in place covering at least some of this capability within the target group and cadence has been established for collection"
            },
            {
                maturity: "4",
                heading: "Mature, Global KPIs",
                description: "S.M.A.R.T. KPIs are now globally in place, automated and refined/iterated on with a regular cadence. Direct links to business goals. Direct link to business performance."
            }
        ]
    },
    adoption: {
        title: 'Adoption',
        isAccordionOpen: false,
        scores: [
            {
                maturity: "0",
                heading: "No Adoption",
                description: "The capability is not in place anywhere within the target group."
            },
            {
                maturity: "1",
                heading: "Siloed Adoption",
                description: "Elements of the capability are being adopted by a siloed group, lacking standardization and evidence best practices."
            },
            {
                maturity: "2",
                heading: "Initial Standardized Adoption",
                description: "Early elements of the capability are being standardized and vetted by a few individuals within the target group."
            },
            {
                maturity: "3",
                heading: "Key Adoption",
                description: "Fully established standardized elements of the capability as a part majority target groups BAU (Business As Usual) behaviors."
            },
            {
                maturity: "4",
                heading: "Full Adoption",
                description: "The capability now exists in totality across all elements of the target group, and is being actively engaged with and worked on."
            }
        ]
    },
    automation: {
        title: 'Automation',
        isAccordionOpen: false,
        scores: [
            {
                maturity: "0",
                heading: "No Automation",
                description: "Virtually all of the required actions within the capability are being executed manually at this stage."
            },
            {
                maturity: "1",
                heading: "Identified Automation Opportunities",
                description: "Starting to identify and map your automation requirements for a solution that will reduce labor and time toil."
            },
            {
                maturity: "2",
                heading: "Experimental Automation",
                description: "Early adoption of some key actions are beginning to be optimised through automated workflows and solutions."
            },
            {
                maturity: "3",
                heading: "Primary Automation",
                description: "Primary or most demanding actions have now been offloaded to automation solutions."
            },
            {
                maturity: "4",
                heading: "Full Automation",
                description: "All material, repeatable tasks within this capability are now automated, any new tasks are reviewed for automation potential or implemented as automation as the capability evolves."
            }
        ]
    }
};

export const lensSlice = createSlice({
    name: 'lenses',
    initialState,
    reducers: {
        openAccordionItem: (state, action) => {
            Object.keys(state).forEach(lensKey => {
                if(lensKey !== action.payload) state[lensKey].isAccordionOpen = false;
            });

            state[action.payload].isAccordionOpen = !state[action.payload].isAccordionOpen;
        }
    }
})

export const { openAccordionItem } = lensSlice.actions

export default lensSlice.reducer