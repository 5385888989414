import {React} from 'react';
import {Button} from "./Button";

export const ProgressIndicator = ({ step, children, className }) => {
    return (
        <div className={"progress-indicator sticky bg-white py-3 bottom-0 " + className}>
            <div className="container mx-auto flex items-center justify-between">
                <p className={'text-xl font-bold mb-0'}>{step}</p>

                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}